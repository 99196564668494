import React from 'react';

import Layout from 'components/layout';
import Seo from 'components/seo';
import Header from 'widgets/@jobseeker/header';
import Steps from 'widgets/@jobseeker/steps';
import Sample from 'widgets/@jobseeker/sample';

function Jobseeker() {
  return (
    <Layout>
      <Seo title="Pintarnya Kerja | BUAT CV" />
      <Header />
      <Steps />
      <Sample />
    </Layout>
  );
}

export default Jobseeker;
