import React from 'react';

import Briefcase from 'assets/icons/briefcase.inline.svg';
import GraduationCap from 'assets/icons/graduation-cap.inline.svg';
import GraduationCapBlue from 'assets/icons/graduation-blue.inline.svg';
import MapPin from 'assets/icons/map-pin.inline.svg';

import './styles.scss';

function CvSample({
  id,
  image,
  name,
  age,
  occupation,
  experience,
  university,
  address,
  work,
  education,
  skills,
  documents,
}) {
  const link = `https://pintarnya.com/cv/${id}`;

  const copyLink = async () => {
    if ('clipboard' in navigator) {
      return navigator.clipboard.writeText(link);
    }
    return document.execCommand('copy', true, link);
  };

  return (
    <div className="cv">
      <div className="cv_profile">
        <div className="cv_applicant">
          <div className="cv_image" style={{ backgroundImage: `url(${image})` }} />
          <div className="cv_profile_content">
            <h3 className="cv_profile_name cv_profile_text_bold text-gilmer-bold">
              {name}
            </h3>
            <p className="cv_profile_text_bold text-gilmer-bold" style={{fontSize: 17}}>{age} Tahun</p>
            <p className="text-gilmer-bold cv_profile_text_bold">{occupation}</p>
            <p className="text-gilmer-bold cv_profile_text">{experience}</p>
            <div>
              <div className="cv_profile_subinfo">
                <GraduationCap />
                <p className="cv_profile_text">{university}</p>
              </div>
              <div className="cv_profile_subinfo">
                <MapPin />
                <p className="cv_profile_text">{address}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="cv_profile_footer">
          <p className="text-link">{link}</p>
          <button
            className="cv_profile_copy text-gilmer-bold"
            type="button"
            onClick={copyLink}
          >
            salin
          </button>
        </div>
      </div>
      <div className="cv_info">
        <div>
          <p className="text-gilmer-bold">Pengalaman Kerja</p>
          <div className="cv_work">
            <div className="cv_work_line" />
            {work.map((job, index) => {
              if (job.positions.length === 1) {
                return (
                  <div key={`${job.company}-${index}`}>
                    <div className="cv_work_title">
                      <div className="cv_work_icon">
                        <Briefcase />
                      </div>
                      <div>
                        <p className="cv_work_company">{job.company}</p>
                        <p className="cv_work_position">{job.positions[0].position}</p>
                        {job.positions[0].dates && job.positions[0].duration && (
                          <p className="cv_work_duration">
                            {job.positions[0].dates} · {job.positions[0].duration}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              }

              return (
                <div key={`${job.company}-${index}`}>
                  <div className="cv_work_title">
                    <div className="cv_work_icon">
                      <Briefcase />
                    </div>
                    <p className="cv_work_company__big">{job.company}</p>
                  </div>
                  {job.positions.map((position) => (
                    <div className="cv_work_section">
                      <div className="cv_work_circle" />
                      <div>
                        <p className="cv_work_position___big">{position.position}</p>
                        {position.dates && position.duration && (
                          <p className="cv_work_duration">
                            {position.dates} · {position.duration}
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              );
            })}
          </div>
        </div>
        <div>
            <p className="text-gilmer-bold">Pendidikan</p>
            <div className="cv_education">
              <div className="cv_education_icon">
                <GraduationCapBlue/>
              </div>
              <p className="cv_education_title">{education}</p>
            </div>
        </div>
      </div>
      <div className="cv_info">
        <p className="text-gilmer-bold">Keahlian</p>
        <div className="cv_skills">
          {skills.map((skill) => (
            <p key={skill} className="cv_tag">
              {skill}
            </p>
          ))}
        </div>
        <p className="text-gilmer-bold">Dokumen dan Sertifikat</p>
        <div>
          {documents.map((document) => (
            <p key={document} className="cv_tag">
              {document}
            </p>
          ))}
        </div>
      </div>
    </div>
  );
}

export default CvSample;
