import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Button from 'components/button';
import Link from 'components/link';
import buttonVariants from 'components/button/constants/buttonVariants';
import ArrowRight from 'assets/icons/arrow-right.inline.svg';

import './styles.scss';

function Steps() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.howItWorks != null);

  const { headline, steps, button } = activeEdge.node.frontmatter.howItWorks;

  return (
    <section className="steps">
      <div className="grid">
        <div className="col-full">
          <h1>{headline}</h1>
          <div className="steps_cards">
            <div className="steps_card___one">
              <p className="steps_label text-gilmer-bold">Langkah 1</p>
              <h3>{steps[0].headline}</h3>
              <p className="steps_content">{steps[0].content}</p>
              <img alt="step one" className="steps_card_image" src={steps[0].image} />
            </div>
            <div className="steps_card___two">
              <p className="steps_label text-gilmer-bold">Langkah 2</p>
              <h3>{steps[1].headline}</h3>
              <p className="steps_content">{steps[1].content}</p>
              <img alt="step two" className="steps_card_image" src={steps[1].image} />
            </div>
            <div className="steps_card___three">
              <p className="steps_label text-gilmer-bold">Langkah 3</p>
              <h3>{steps[2].headline}</h3>
              <p className="steps_content">{steps[2].content}</p>
              <img alt="step three" className="steps_card_image" src={steps[2].image} />
            </div>
          </div>
          <Link className="steps_button" to={button.link}>
            <Button variant={buttonVariants.SECONDARY}>
              {button.text}
              <ArrowRight className="button_icon" />
            </Button>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Steps;

const query = graphql`
  query StepsQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            howItWorks {
              headline
              steps {
                headline
                content
                image
              }
              button {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`;
