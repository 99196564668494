import React from 'react';

import { StaticImage } from 'gatsby-plugin-image';
import { useStaticQuery, graphql } from 'gatsby';

import Button from 'components/button';
import buttonVariants from 'components/button/constants/buttonVariants';
import Link from 'components/link';

import './styles.scss';

function Header() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.jsHeader != null);

  const { headline, primaryButton, secondaryButton } =
    activeEdge.node.frontmatter.jsHeader;

  return (
    <section className="jsheader">
      <div className="jsheader_container grid">
        <div className="start-1 col-5 col-xl-4 col-md-full">
          <div>
            <h1 className="text-title jsheader_h1">{headline}</h1>
            <div className="jsheader_buttons">
              <Link to={primaryButton.link}>
                <Button>{primaryButton.text}</Button>
              </Link>
              <Link to={secondaryButton.link}>
                <Button variant={buttonVariants.SECONDARY}>
                  {secondaryButton.text}
                </Button>
              </Link>
            </div>
          </div>
        </div>
        <div className="jsheader_image col-6 col-xl-4 col-md-full">
          <StaticImage
            alt="header"
            src="../../../assets/jobseeker/header/header.png"
            placeholder="blurred"
          />
        </div>
      </div>
    </section>
  );
}

export default Header;

const query = graphql`
  query JSHeaderQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            jsHeader {
              headline
              primaryButton {
                text
                link
              }
              secondaryButton {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`;
