import React from 'react';

import { useStaticQuery, graphql } from 'gatsby';

import Cv from 'components/cv';

import './styles.scss';
import CvSample from 'components/cv/sample';
import Button from 'components/button';
import Link from 'components/link';
import buttonVariants from 'components/button/constants/buttonVariants';
import ArrowRight from 'assets/icons/arrow-right.inline.svg';

function Sample() {
  const {
    allMarkdownRemark: { edges },
  } = useStaticQuery(query);

  const activeEdge = edges.find((edge) => edge?.node?.frontmatter?.cv != null);

  const { headline, jobseeker, button } = activeEdge.node.frontmatter.cv;

  return (
    <section className="sample grid" id="samplecv">
      <div className="start-1 col-full">
        <h1 className="sample_headline">{headline}</h1>
      </div>
      <div className="start-1 col-full">
        <CvSample {...jobseeker} />
        <Link className="sample_button" to={button.link}>
          <Button variant={buttonVariants.SECONDARY}>
            {button.text}
            <ArrowRight className="button_icon" />
          </Button>
        </Link>
      </div>
    </section>
  );
}

export default Sample;

const query = graphql`
  query CVQuery {
    allMarkdownRemark {
      edges {
        node {
          frontmatter {
            cv {
              jobseeker {
                image
                id
                address
                age
                documents
                education
                experience
                image
                name
                occupation
                skills
                university
                work {
                  company
                  positions {
                    dates
                    duration
                    position
                  }
                }
              }
              headline
              button {
                text
                link
              }
            }
          }
        }
      }
    }
  }
`;
